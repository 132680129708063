<template>
  <HorizontalScrollablePage @get:negativeMargin="getNegativeMargin">
    <template #scrollableContent>
      <ScrollingCardsView
        v-if="recentlyAdded.length"
        row="recentlyAdded"
        :loading="loadingResources"
        :negativeMargin="negativeMargin"
        heading="Recently added for you"
        :resources="recentlyAdded"
        displaySeeAll
        @see:all="seeAllFromHomePage"
      />
      <ScrollingCardsView
        v-if="mostViewed.length"
        row="mostViewed"
        :loading="loadingResources"
        :negativeMargin="negativeMargin"
        heading="Most viewed for you"
        :resources="mostViewed"
        displaySeeAll
        @see:all="seeAllFromHomePage"
      />
      <ScrollingCardsView
        v-if="mostLiked.length"
        row="mostLiked"
        :loading="loadingResources"
        :negativeMargin="negativeMargin"
        heading="Most liked for you"
        :resources="mostLiked"
        displaySeeAll
        @see:all="seeAllFromHomePage"
      />
      <ScrollingCardsView
        v-if="mostPopularRandN.length && showTLRResources"
        row="mostPopularRandN"
        :loading="loadingResources"
        :negativeMargin="negativeMargin"
        heading="Most popular from Reading and Numeracy"
        :resources="mostPopularRandN"
        displaySeeAll
        @see:all="seeAllFromHomePage"
      />
      <ScrollingCardsView
        v-if="mostPopularStem.length && showTLRResources"
        row="mostPopularStem"
        :loading="loadingResources"
        :negativeMargin="negativeMargin"
        heading="Most popular from stem.T4L"
        :resources="mostPopularStem"
        displaySeeAll
        @see:all="seeAllFromHomePage"
      />
    </template>
  </HorizontalScrollablePage>
</template>

<script>
import {mapGetters} from 'vuex';
import ScrollingCardsView from '@/views/HubResourcesPage/ScrollingCardsView';
import HorizontalScrollablePage from '@/components/HorizontalScrollablePage';
import {SearchQueryParams, SortField, SortDirection} from 'resource_store_vue_client';

export default {
  name: 'HomePage',
  components: {
    ScrollingCardsView,
    HorizontalScrollablePage
  },
  data() {
    return {
      loadingResources: false,
      recentlyAdded: [],
      mostViewed: [],
      mostLiked: [],
      mostPopularRandN: [],
      mostPopularStem: [],
      negativeMargin: '',
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/userProfile',
      isSearchScopeChanged: 'isSearchScopeChanged'
    }),
    resourceCategory() {
      if (this.$store.getters.resourceCategory) {
        return this.$store.getters?.resourceCategory?.length > 1 ? '' : this.$store.getters?.resourceCategory[0] || '';
      } else {
        return ''
      }
    },
    sources() {
      return this.$store.getters.filters['resource-sources'];
    },
    showAllCategories() {
      return this.resourceCategory?.length === 0;
    },
    isDefaultView() {
      return this.$store.state.keywords === '' && this.$store.state.aiInput === '' && this.$store.getters.isFiltersEmpty;
    },
    showTLRResources() {
      return this.resourceCategory.includes('tlr') || this.showAllCategories
    }
  },
  watch: {
    userProfile: {
      immediate: true,
      deep: true,
      async handler(userProfile) {
        if (userProfile && !userProfile.onBoarded) {
            console.log('A: not onBoarded');

          //this.getResourcesForHomePage();

          // if not onboarded load all categories
          this.$store.commit('SET_RESOURCE_CATEGORY', []);

        } else if (userProfile && userProfile.preferences) {
            console.log('B: userPreference');

          // if preferences are loaded then save resource category in store
          const preferences = userProfile.preferences;
          let resourceCategories = [];
          if (preferences.years.length > 0 || preferences.learningAreas.length > 0) {
            resourceCategories.push('tlr')
          }

          if (preferences.schoolImprovementSources.length > 0) resourceCategories.push('sir')
          await this.$store.commit('SET_RESOURCE_CATEGORY', resourceCategories);

          //this.getResourcesForHomePage();
        }
      }
    },
    isDefaultView: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          console.log('AAA: isDefaultView: ' + val);
          this.getResourcesForHomePage();
        }
      }
    }
  },
  methods: {
    getNegativeMargin(val) {
      this.negativeMargin = val;
    },
    seeAllFromHomePage(row) {
      let filters;
      let sortSearchObj;
      switch (row) {
        case 'recentlyAdded':
          this.$store.commit('SET_PREFERENCES_MODE', true); // preferencesMode = first 3 rows in seeAllFromHomePage
          sortSearchObj = {
            field:'updated',
            direction: 'desc'
          };
          this.$store.commit('SET_SORT', sortSearchObj);
          this.$store.dispatch('fetchResourcesForHomePageWithClient', sortSearchObj);
          break;
        case 'mostViewed':
          this.$store.commit('SET_PREFERENCES_MODE', true);
          sortSearchObj = {
            field:'numberViews',
            direction: 'desc'
          };
          this.$store.commit('SET_SORT', sortSearchObj);
          this.$store.dispatch('fetchResourcesForHomePageWithClient', sortSearchObj);
          break;
        case 'mostLiked':
          this.$store.commit('SET_PREFERENCES_MODE', true);
          sortSearchObj = {
            field:'numberLikes',
            direction: 'desc'
          };
          this.$store.commit('SET_SORT', sortSearchObj);
          this.$store.dispatch('fetchResourcesForHomePageWithClient', sortSearchObj);
          break;
        case 'mostPopularRandN':
          filters = {
            library: '',
            'resource-years': '',
            'resource-klas': '',
            'resource-types': '',
            'resource-sources': 'readingandnumeracy',
            'resource-randntopics': '',
            // new query parameters in R4 for Hub:
            formats: '',
            resourcetype: '', // Hub-Type, checkboxHubType
            phase: '',
            sefalignment: '',
            focusarea: ''
          };
          this.$store.commit('SET_FILTERS', filters);
          sortSearchObj = {
            field:'numberViews',
            direction: 'desc'
          };
          this.$store.commit('SET_SORT', sortSearchObj);
          this.$store.commit('SET_SEARCH_MODE', true); // last two rows are just normal search
          this.$store.dispatch('fetchResourcesWithClient');
          break;
        case 'mostPopularStem':
          filters = {
            library: '',
            'resource-years': '',
            'resource-klas': '',
            'resource-types': '',
            'resource-sources': 'stem',
            'resource-randntopics': '',
            // new query parameters in R4 for Hub:
            formats: '',
            resourcetype: '', // Hub-Type, checkboxHubType
            phase: '',
            sefalignment: '',
            focusarea: ''
          };
          this.$store.commit('SET_FILTERS', filters);
          sortSearchObj = {
            field:'numberViews',
            direction: 'desc'
          };
          this.$store.commit('SET_SORT', sortSearchObj);
          // this.$store.commit('SET_SORT', 'numberViews:desc');
          this.$store.commit('SET_SEARCH_MODE', true);
          // this.$store.dispatch('fetchResources');
          this.$store.dispatch('fetchResourcesWithClient');
          break;
      }
      window.scrollTo(0, 0);
    },
    getRequestConfig() {
      const queries = {};

      const resourceCategory = this.$store.state.resourceCategory.join(',');
      if (resourceCategory) {
        queries.filters = [
          {
            field: SearchQueryParams.ResourceCategory,
            values: resourceCategory || ''
          }
        ];
      }

      const isLearningResource = resourceCategory.includes('tlr');
      const isSIResource = resourceCategory.includes('sir');

      // base the resource-klas, resource-years and resource-sources on the category to avoid BE errors
      if (isLearningResource) {
        const klas = this.$store.getters['users/preferences']?.learningAreas.join(',') || '';
        if (klas) {
          queries.filters.push(
            {
              field: SearchQueryParams.KLA,
              values: klas
            }
          );
        }
        const years = this.$store.getters['users/preferences']?.years.join(',') || '';
        if (years) {
          queries.filters.push(
            {
              field: SearchQueryParams.Year,
              values: years
            }
          );
        }
      }
      if (isSIResource) {
        const schoolImprovementSources = this.$store.getters['users/preferences']?.schoolImprovementSources.join(',') || [];
        if (schoolImprovementSources.length > 0) {
          queries.filters.push(
            {
              field: SearchQueryParams.Source,
              values: schoolImprovementSources
            }
          );
        }
      }

      return queries;
    },
    async getResourcesForHomePage() {
      // exit if filters and keywords are not empty
      if (!this.isDefaultView) return false

      this.loadingResources = true;
      this.$emit('onLoadingStatusChange', true)

      if (this.showTLRResources) {
        try {
          let mostPopularRandN = await this.$store.dispatch('fetchResourcesForHomePageWithClient', {
            library: 'readingandnumeracy',
            sort: {
              field: SortField.NUMBER_VIEWS,
              direction: SortDirection.DESC
            }
          });
          this.mostPopularRandN = mostPopularRandN ? mostPopularRandN : [];
        } catch (err) {
          console.error(err);
        }

        try {
          // let mostPopularStem = await this.$store.dispatch('fetchResourcesForHomePage', 'library=stem');
          let mostPopularStem = await this.$store.dispatch('fetchResourcesForHomePageWithClient', {
            library: 'stem',
            sort: {
              field: SortField.NUMBER_VIEWS,
              direction: SortDirection.DESC
            }
          });
          this.mostPopularStem = mostPopularStem ? mostPopularStem : [];
        } catch (err) {
          console.error(err);
        }
      }

      try {
        // let recentlyAdded = await this.$store.dispatch('fetchResourcesForHomePage', `sort=updated:desc${this.getQueries()}`);
        const searchObj = this.getRequestConfig();
        searchObj.sort = {
          field: SortField.UPDATED,
          direction: SortDirection.DESC
        };
        searchObj.isHomePage = true;
        let recentlyAdded = await this.$store.dispatch('fetchResourcesForHomePageWithClient', searchObj);
        this.recentlyAdded = recentlyAdded ? recentlyAdded : [];
      } catch (err) {
        console.error(err);
      }

      try {
        const searchObj = this.getRequestConfig();
        searchObj.sort = {
          field: SortField.NUMBER_VIEWS,
          direction: SortDirection.DESC
        };
        searchObj.isHomePage = true;
        let mostViewed = await this.$store.dispatch('fetchResourcesForHomePageWithClient', searchObj);
        this.mostViewed = mostViewed ? mostViewed : [];
      } catch (err) {
        console.error(err);
      }

      try {
        const searchObj = this.getRequestConfig();
        searchObj.sort = {
          field: SortField.NUMBER_LIKES,
          direction: SortDirection.DESC
        };
        searchObj.isHomePage = true;
        let mostLiked = await this.$store.dispatch('fetchResourcesForHomePageWithClient', searchObj);
        this.mostLiked = mostLiked ? mostLiked : [];
      } catch (err) {
        console.error(err);
      }

      this.loadingResources = false;
      this.$emit('onLoadingStatusChange', false)
    }
  }
}
</script>
