import {get, post, put} from '@/api/http';
import Vue from 'vue';
import axios from 'axios';

/**
 * Fetches the user from the API or returns null if it doesn't exist
 * @param baseUrl
 * @param uid
 * @returns {Promise<User | null>}
 */
export async function getUser(baseUrl, uid) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/users/${uid}`, config);
    return res.data;
  } catch (err: any) {
    if (err.response && err.response.status === 404) {
      return null;
    }
    throw err;
  }
}

export async function createUser(baseUrl, userRequest) {
  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    }
  };
  const res = await post(`${baseUrl}/users/addUser`, userRequest, config);
  return res.data;
}

export async function getPreferences(baseUrl, uid) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/preference/${uid}`, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function sendPreferences(baseUrl, payload) {
  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    }
  };
  const res = await post(`${baseUrl}/preference/${payload.userId}`, payload.preferencesRequest, config);
  return res.data;
}

export async function dismissNotification(baseUrl, payload) {
  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    }
  };
  const res = await axios.patch(`${baseUrl}/users/${payload.uid}?option=dismissNotification`, {
    notification: payload.notification
  }, config);
  return res.data;
}

export async function updatePreferences(baseUrl, payload) {
  const config = {
    headers: {
      ...Vue.prototype.$OAuth.buildHttpHeader({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    }
  };
  const res = await put(`${baseUrl}/preference/${payload.userId}`, payload.preferencesRequest, config);
  return res.data;
}

/*
export async function getUpsConfig(baseUrl) {
  try {
    const config = {
      headers: {
        ...Vue.prototype.$OAuth.buildHttpHeader({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      }
    };
    const res = await get(`${baseUrl}/ups-config`, config);
    return res.data;
  } catch (err) {
    console.error(err);
  }
}
 */
