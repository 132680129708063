import Vue from 'vue';
import {apiBaseUrl, apiBaseUrlV2, collectionBaseUrl, searchBaseUrl} from '../config';
import {delay} from '../helpers'
import axios from 'axios';
import api from '@/api';
import {setNewCollectionFlag, orderCollections} from '@/transforms';
import {DEFAULT_MAX_NO_OF_COLLECTIONS, EDPUSH_ACTION, RESOURCE_SCOPES, ResourceStatus} from '@/constants';

const CONFIG = (window as any).dlrhConfig;
const FETCH_COLLECTIONS_URL = `${apiBaseUrlV2}/search`;

const moduleMyCollections = {
  namespaced: true,
  state: {
    showCards: true,
    userScroll: false,
    sorting: 'updated:desc',
    resourceSorting: '',
    sortedSubResources: [],
    pageNum: 1,
    searchMode: false,
    queryAction: '',
    keywords: '',
    showEditMetaDataDialog: false,
    discussionContent: '',
    errorCreateCollection: false,
    successCreateCollection: false,
    collectionsNotification: [],
    collectionCreatedByMe: {
      resources: [],
      meta: {
        count: 0
      }
    },
    collectionFollowedByMe: {
      resources: [],
      meta: {
        count: 0
      }
    },
    collectionSharedWithMe: {
      resources: [],
      meta: {
        count: 0
      }
    },
    collectionSharedByMe: {
      resources: [],
      meta: {
        count: 0
      }
    },
    collectionsEditable: {
      resources: [],
      meta: {
        count: 0
      }
    },
    searchResults: {
      resources: [],
      meta: {
        count: 0,
        itemsReturned: 0
      },
    },
    filters: {
      'resource-stages': '',
      'resource-klas': '',
      myCollectionLibraries: '',
    },
    filtersSelection: { // filters selection by the user, source of v-model on <ChipFilters>.
      checkboxStages: [],
      checkboxKLAs: [],
      checkboxMyCollectionLibraries: [],
    },
    collections: [],
    collectionDetails: {},
    collectionAccess: {},
    subResources: [],
    discussion: [],
    notification: [],
    collectionsSequence: [],
    newCreatedCollection: {},
  },
  getters: {
    errorCreateCollection: state => state.errorCreateCollection,
    successCreateCollection: state => state.successCreateCollection,
    showCards: state => state.showCards,
    sorting: state => state.sorting,
    searchMode: state => state.searchMode,
    searchResults: state => state.searchResults.resources,
    searchResultsMetaData: state => state.searchResults.meta,
    searchResultsCount: state => state.searchResults.meta.count,
    collectionFollowedByMe: state => state.collectionFollowedByMe,
    collectionCreatedByMe: state => state.collectionCreatedByMe,
    createdByMeCount: state => state.collectionCreatedByMe.meta.count || [],
    collectionSharedWithMe: state => state.collectionSharedWithMe,
    sharedWithMeCount: state => state.collectionSharedWithMe.meta.count || [],
    collectionSharedByMe: state => state.collectionSharedByMe,
    sharedByMeCount: state => state.collectionSharedByMe.meta.count || [],
    collectionAccess: state => state.collectionAccess,
    notification: state => state.notification,
    collectionsNotification: state => state.collectionsNotification,
    collectionSequence: state => state.collectionsSequence,
    newCreatedCollection: state => state.newCreatedCollection,
    collections: state => state.collections.map(collection => {
      const members = [...collection.userAccess.owner, ...collection.userAccess.editor, ...collection.userAccess.viewer]
      return {
        ...collection.document,
        Application: collection.audit.iss,
        Members: members,
        UserAccess: collection.userAccess
      }
    }),
    myEditableCollections: state => state.collectionsEditable,
    myCollections: state => { // only be used in the default fetch api in table view
      return {
        'resources': [
          ...state.collectionFollowedByMe.resources,
          ...state.collectionCreatedByMe.resources,
          ...state.collectionSharedWithMe.resources,
          ...state.collectionSharedByMe.resources],
        'meta': {
          'count': state.collectionFollowedByMe.meta.count
            + state.collectionSharedWithMe.meta.count
            + state.collectionSharedWithMe.meta.count
            + state.collectionSharedByMe.meta.count,
          'itemsReturned': parseInt(state.collectionFollowedByMe.meta.itemsReturned)
            + parseInt(state.collectionSharedByMe.meta.itemsReturned)
            + parseInt(state.collectionSharedByMe.meta.itemsReturned)
            + parseInt(state.collectionSharedWithMe.meta.itemsReturned),
          'page': state.collectionCreatedByMe.meta.page,
          'totalPages': parseInt(state.collectionCreatedByMe.meta.totalPages),
        }
      }
    },
    discussionContent: state => state.discussionContent,
    getCollection: state => state.collectionDetails, // collection requested according to user click
    filters: state => state.filters,
    discussion: state => state.discussion, // discussion panel
    isFiltersEmpty: state => {
      for (const filter in state.filters) {
        if (state.filters[filter] !== '') {
          return false;
        }
      }
      return true;
    }
  },
  mutations: {
    SHOW_CARDS(state, val) {
      state.showCards = val
    },
    SET_SORTING(state, val) {
      state.sorting = val;
    },
    SET_RESOURCE_SORTING(state, val) {
      state.resourceSorting = val;
    },
    SET_SORTED_SUB_RESOURCES(state, val) {
      state.sortedSubResources = val
    },
    SET_PAGE_NUM(state, val) {
      state.pageNum = val
    },
    INCREASE_PAGE_NUM(state) {
      state.pageNum += 1
    },
    SET_SEARCH_RESULTS(state, val) {
      if (state.userScroll && state.pageNum > 1) {
        state.searchResults.resources = state.searchResults.resources.concat(val.resources);
      } else {
        state.searchResults.resources = val.resources;
      }
      state.searchResults.meta = {
        'count': val.meta.count,
        'itemsReturned': parseInt(val.meta.itemsReturned),
        'page': val.meta.page,
        'totalPages': val.meta.totalPages,
      }
      console.log('setting search results... ', state.searchResults)
    },
    RESET_SEARCH(state) {
      state.searchMode = false
      state.userScroll = false
      state.keywords = ''
      state.queryAction = ''
      state.filters = {
        'resource-stages': '',
        'resource-klas': '',
        myCollectionLibraries: ''
      }
      state.searchResults = {
        resources: [],
        meta: {
          count: 0,
          itemsReturned: 0
        },
      }
    },
    SET_SHOW_EDIT_METADATA_DIALOG(state, value) {
      state.showEditMetaDataDialog = value
    },
    SET_ERROR_CREATE_DIALOG(state, value) {
      state.errorCreateCollection = value
    },
    SET_SUCCESS_CREATE_DIALOG(state, value) {
      state.successCreateCollection = value
    },
    SET_QUERY_ACTION(state, val) {
      state.queryAction = val
    },
    SET_SEARCH_MODE(state, val) {
      state.pageNum = 1;
      state.searchMode = val;
    },
    SET_KEYWORDS(state, val) {
      state.keywords = val;
    },
    SET_FILTERS(state, filters) {
      state.filters = Object.assign(state.filters, filters);
    },
    SET_FILTERS_SELECTION(state, value) {
      state.filtersSelection = value;
    },
    SET_DISCUSSION_CONTENT(state, val) {
      state.discussionContent = val
    },
    SET_USER_SCROLL(state) {
      state.userScroll = true;
    },
    RESET_USER_SCROLL(state) {
      state.userScroll = false;
    },
    SET_COLLECTIONS(state, collections) {
      state.collections = collections
    },
    SET_COLLECTION_DETAILS(state, collection) {
      state.collectionDetails = collection
    },
    SET_COLLECTION_ACCESS(state, access) {
      state.collectionAccess = access
    },
    SET_SUB_RESOURCES(state, val) {
      state.subResources = val
    },
    SET_NOTIFICATION(state, val) {
      state.notification = val
    },
    /**
     * @notification mutiation
     */
    SET_COLLECTION_NOTIFICATION(state, val) {
      state.collectionsNotification = val;
    },
    /**'
     * @discussion mutation
     */
    SET_DISCUSSION(state, val) {
      state.discussion = val;
    },
    SET_NEW_CREATED_COLLECTION(state, val) {
      state.newCreatedCollection = val
    },
    SET_MY_EDITABLE_COLLECTIONS(state, val) {
      state.collectionsEditable.meta = val.meta;
      if (state.userScroll) {
        state.collectionsEditable.resources = state.collectionsEditable.resources.concat(val.resources);
      } else {
        state.collectionsEditable.resources = val.resources;
      }
    },
    SET_COLLECTION_FOLLOWED_BY_ME(state, val) {
      state.collectionFollowedByMe.meta = val.meta;
      if (state.userScroll) {
        state.collectionFollowedByMe.resources = state.collectionFollowedByMe.resources.concat(val.resources);
      } else {
        state.collectionFollowedByMe.resources = val.resources;
      }
    },
    SET_COLLECTION_CREATED_BY_ME(state, val) {
      state.collectionCreatedByMe.meta = val.meta;
      if (state.userScroll) {
        state.collectionCreatedByMe.resources = state.collectionCreatedByMe.resources.concat(val.resources);
      } else {
        state.collectionCreatedByMe.resources = val.resources;
      }
    },
    SET_COLLECTION_SHARED_BY_ME(state, val) {
      state.collectionSharedByMe.meta = val.meta;
      if (state.userScroll) {
        state.collectionSharedByMe.resources = state.collectionSharedByMe.resources.concat(val.items);
      } else {
        state.collectionSharedByMe.resources = val.resources;
      }
    },
    SET_COLLECTION_SHARED_WITH_ME(state, val) {
      state.collectionSharedWithMe.meta = val.meta;
      if (state.userScroll) {
        state.collectionSharedWithMe.resources = state.collectionSharedWithMe.resources.concat(val.items);
      } else {
        state.collectionSharedWithMe.resources = val.resources;
      }
    },
    SET_COLLECTION_SEQUENCE(state, val) {
      state.collectionsSequence = val
    }
  },
  actions: {
    async createDiscussion({commit}, payload) {
      console.log(payload)
      commit('SET_DISCUSSION_CONTENT', payload)
    },
    async createCollection({commit, rootGetters, rootState}, {document, subResources, hubCollection, application = CONFIG.APPLICATION, source = 'collections', resourceCategory = []}) {
      commit('SET_IS_LOADING', true, {root: true});
      try {
        console.log('new collection', document)
        // headers including oAuth tokens
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'multipart/form-data',
              application,
              source,
              uid: rootState.uid,
              sessionid: rootState.sessionId,
              id: document.ItemID
            })
          }
        };

        const collectionURL = `${collectionBaseUrl}/collection/createCollection${hubCollection ? '?action=savedraft' : ''}`;

        const bodyFormData = new FormData();

        bodyFormData.append('draft', JSON.stringify({
          ...document,
          Source: source,
          DateCreated: new Date().toISOString().substr(0, 19), // remove micro seconds
          DateModified: new Date().toISOString().substr(0, 19),
          DateResCreated: new Date().toISOString().substr(0, 19),
          ...(hubCollection && {
            ResourceCategory: resourceCategory
          }) // for now adding this only for hub collections
        }));

        if (subResources) {
          bodyFormData.append('subResources', subResources);
        }

        bodyFormData.append('isResourceCollection','true');

        const collection = await axios.post(collectionURL, bodyFormData, config).finally(() => {
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        });

        // logging for creating
        const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
        const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
        const _config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              userid: rootState.uid,
              school_codes: schoolCodes,
              email: rootState.email,
              sessionid: rootState.sessionId,
              roles: roles
            })
          }
        };
        await axios.patch(`${searchBaseUrl}/urhsearch/${document.ItemID}?action=createcollection`, {}, _config);

        const alert = {
          type: 'success',
          text: 'Your collection is successfully created.'
        }
        commit('SET_NEW_CREATED_COLLECTION', collection.data)
        // only show the invite members dialog to my collection
        !hubCollection && commit('SET_SUCCESS_CREATE_DIALOG', true);
        commit('SET_ALERT', alert, {root: true});

        return collection?.data;
      } catch (e) {
        const alert = {
          type: 'error',
          text: 'There was an error saving your resource.'
        }
        commit('SET_ALERT', alert, {root: true});
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async fetchMyEditableCollections(
      {commit, state, rootState, rootGetters},
      {keywords = state.keywords, hubCollection = false, newCollectionId = undefined, page = 1} = {}
    ) {
      if (state.loading) {
        await commit('SET_IS_LOADING', true, {root: true});
      }

      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            'Content-Type': 'application/json',
            application: CONFIG.APPLICATION,
            source: hubCollection? CONFIG.SOURCE : 'collections',
            uid: rootState.uid,
          })
        }
      };

      const query: any = {
        page,
        size: rootState.users.upsConfig?.maxNumCollections || DEFAULT_MAX_NO_OF_COLLECTIONS, // load all resources ideally should be more than maxNumCollections
        sort: 'updated:desc',
        noArchive:true,
        ...(keywords && {keyword: keywords}),
        ...(hubCollection && {
          searchDraftAndDocument: true,
          Source: rootGetters['users/availableContentGroups'].map(({id}) => id),
          'record.isResourceCollection': true
        })
      }

      const body = hubCollection
        ? {}
        : {
          'customQuery': {
            'bool': {
              'minimum_should_match': 1,
              'should': [
                {
                  'terms': {
                    'userAccess.owner': [
                      rootState.uid
                    ]
                  }
                },
                {
                  'terms': {
                    'userAccess.editor': [
                      rootState.uid
                    ]
                  }
                }
              ]
            }
          }
        };

      console.log('new fetch queries', query);
      // console.log('queries', query);

      const url = new URL(FETCH_COLLECTIONS_URL, apiBaseUrl);
      // const url = new URL(FETCH_COLLECTIONS_URL + `/${payload?.action || ''}`, apiBaseUrl);
      url.search = new URLSearchParams(query).toString();

      // fetch resources
      const response = await axios.post(url.toString(), body, config)

      // update store
      // await commit('SET_MY_EDITABLE_COLLECTIONS', response.data);
      await commit('SET_MY_EDITABLE_COLLECTIONS', orderCollections(setNewCollectionFlag(response.data, newCollectionId)));
    },
    async fetchCollections(
      {commit, state, rootState},
      {access = '', perPage = 12, page = state.pageNum, keywords = state.keywords, source = 'collections'} = {}
    ) {

      if (state.loading) {
        await commit('SET_IS_LOADING', true, {root: true});
      }

      const seeAllView = (state.searchMode && state.queryAction !== '')
      let librariesArr: string[] = []
      if ( state.filters.myCollectionLibraries && state.filters.myCollectionLibraries.length > 0) {
        librariesArr = state.filters.myCollectionLibraries.split(',');
      }
      // console.log('seeAllView', seeAllView, state.queryAction)

      const searchOnlyInHub = state.queryAction === 'followedbyme' || access === 'followedbyme' || (librariesArr.length === 1 && librariesArr.includes('followedbyme'))
      const searchInHubAndMyCollections = (librariesArr.length > 0 && librariesArr.includes('followedbyme')) || access === 'all' || (state.searchMode && librariesArr.includes('followedbyme') || (state.searchMode && librariesArr.length === 0) && !seeAllView)
      console.log('searchOnlyInHub', searchOnlyInHub)
      console.log('searchHubAndMyCollections', searchInHubAndMyCollections, librariesArr, access, state.queryAction, state.searchMode)

      /**
       * state.queryAction is for loading the 4 different types of collections
       *       1. createdbyme
       *       2. followedbyme
       *       3. sharedwithme
       *       4. sharedbyme
       */
      /**
       * ALIAS searchmycollections is for (hub + my) collections
       * ALIAS dlrh|CONFIG.SOURCE is for hub collections
       * collections is for searching collections in urh_collections repository
       */
      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            'Content-Type': 'application/json',
            application: CONFIG.APPLICATION,
            source: searchOnlyInHub ? CONFIG.SOURCE : searchInHubAndMyCollections ? 'searchmycollections' : 'collections', //(state.searchMode && !seeAllView) ? 'searchmycollections' : (access === 'followedbyme' || librariesArr.includes('followedbyme')) ? CONFIG.SOURCE : 'collections',
            uid: rootState.uid,
          })
        }
      };

      console.log('state.queryAction', state.queryAction, access)
      const queryAction = state.queryAction || access;

      const query: any = {
        page,
        size: perPage,
        'record.status': 5,
        sort: state.sorting,
        noArchive:true,
        'record.isResourceCollection': true,
        ...(keywords && {keyword: keywords})
      }
      let body = {}
      if ((state.searchMode && !state.queryAction) || access === 'all') {
        body = {
          'customQuery': {
            'bool': {
              'minimum_should_match': 1,
              'should': [
                {
                  'terms': {
                    'userAccess.owner': [
                      rootState.uid
                    ]
                  }
                },
                {
                  'terms': {
                    'userAccess.editor': [
                      rootState.uid
                    ]
                  }
                },
                {
                  'terms': {
                    'userAccess.viewer': [
                      rootState.uid
                    ]
                  }
                }
                ,{
                  'terms': {
                    'userAccess.followers': [
                      rootState.uid
                    ]
                  }
                }
              ]
            }
          }
        };
        if (state.filters['resource-stages']) { // MyResource page
          query.Stages = state.filters['resource-stages']
        }
        if (state.filters['resource-klas']) { // MyResource page
          query.KLA = state.filters['resource-klas']
        }

        let bodyLibraries: any = {
          'customQuery': {
            'bool': {
              //'minimum_should_match': 1,
              //'should': []
              'filter': {
                'bool': {
                  'should': []
                }
              }
            }
          }
        };
        const shouldFollowedByMe = {
          'terms': {
            'userAccess.followers': [
              rootState.uid
            ]
          }
        };
        const shouldCreatedByMe = {
          'terms': {
            'userAccess.owner': [
              rootState.uid
            ]
          }
        };
        const shouldSharedWithMeEditor = {
          'terms': {
            'userAccess.editor': [
              rootState.uid
            ]
          }
        };
        const shouldSharedWithMeViewer = {
          'terms': {
            'userAccess.viewer': [
              rootState.uid
            ]
          }
        };
        const mustSharedByMeUser = {
          'term': {
            'userAccess.owner': rootState.uid
          }
        };
        const mustSharedByMeScope = {
          'term': {
            'userAccess.scope': 1
          }
        };
        if (librariesArr.length > 0) {
          // custom query requires when librariesArr filter is used
        if (librariesArr?.length === 1) {
          switch (librariesArr[0]) {
            case 'followedbyme': {
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldFollowedByMe);
              break;
            }
            case 'createdbyme': {
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldCreatedByMe);
              break;
            }
            case 'sharedwithme': {
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeEditor);
              bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeViewer);
              break;
            }
            case 'sharedbyme': {
              bodyLibraries = {
                'customQuery': {
                  'bool': {
                    'filter': {
                      'bool': {
                        'must': [] // this needs to change to a must (i.e. AND)
                      }
                    }
                  }
                }
              }
              bodyLibraries.customQuery.bool.filter.bool.must.push(mustSharedByMeUser);
              bodyLibraries.customQuery.bool.filter.bool.must.push(mustSharedByMeScope);
              break;
            }
          }
        // } else if (librariesArr?.length === 2) {
        //   if (librariesArr.includes('createdbyme') && librariesArr.includes('sharedwithme')) {
        //     bodyLibraries.customQuery.bool.filter.bool.should.push(shouldCreatedByMe);
        //     bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeEditor);
        //     bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeViewer);
        //   } else if (librariesArr.includes('createdbyme') && librariesArr.includes('sharedbyme')) {
        //     bodyLibraries = {
        //       'customQuery': {
        //         'bool': {
        //           'filter': {
        //             'bool': {
        //               'must': {
        //                 'term': {
        //                   'userAccess.owner': rootState.uid
        //                 }
        //               },
        //               'should': [
        //                 {
        //                   'bool': {
        //                     'filter': {
        //                       'term': {
        //                         'userAccess.scope': 1
        //                       }
        //                     }
        //                   }
        //                 }
        //               ]
        //             }
        //           }
        //         }
        //       }
        //     };
        //   } else if (librariesArr.includes('sharedwithme') && librariesArr.includes('sharedbyme')) {
        //     bodyLibraries = {
        //       'customQuery': {
        //         'bool': {
        //           'filter': {
        //             'bool': {
        //               'should': [
        //                 {
        //                   'term': {
        //                     'userAccess.editor': rootState.uid
        //                   }
        //                 },
        //                 {
        //                   'term': {
        //                     'userAccess.viewer': rootState.uid
        //                   }
        //                 },
        //                 {
        //                   'bool': {
        //                     'must': [
        //                       {
        //                         'term': {
        //                           'userAccess.owner': rootState.uid
        //                         }
        //                       },
        //                       {
        //                         'term': {
        //                           'userAccess.scope': 1
        //                         }
        //                       }
        //                     ]
        //                   }
        //                 }
        //               ]
        //             }
        //           }
        //         }
        //       }
        //     };
        //   }
        } else if (librariesArr?.length > 1) {
          if (librariesArr.includes('sharedwithme')) {
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeEditor);
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldSharedWithMeViewer);
          }
          if (librariesArr.includes('sharedbyme')) {
            bodyLibraries.customQuery.bool.filter.bool.should.push(mustSharedByMeUser);
            bodyLibraries.customQuery.bool.filter.bool.should.push(mustSharedByMeScope);
          }
          if (librariesArr.includes('createdbyme')){
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldCreatedByMe);
          }
          if (librariesArr.includes('followedbyme')) {
            bodyLibraries.customQuery.bool.filter.bool.should.push(shouldFollowedByMe);
          }
        }
          body = bodyLibraries;
        }

      } else if (queryAction === 'createdbyme') {
        query['userAccess.owner'] = rootState.uid
      } else if (queryAction === 'followedbyme') {
        query['userAccess.followers'] = rootState.uid
      } else if (queryAction === 'sharedbyme') {
        query['userAccess.owner'] = rootState.uid
        query['userAccess.scope'] = 1
      } else if (queryAction === 'sharedwithme') {
        body = {
          'customQuery': {
            'bool': {
              'minimum_should_match': 1,
              'should': [
                {
                  'terms': {
                    'userAccess.editor': [
                      rootState.uid
                    ]
                  }
                },
                {
                  'terms': {
                    'userAccess.viewer': [
                      rootState.uid
                    ]
                  }
                }
              ]
            }
          }
        };
      }

      console.log('queries', query);
      console.log('query action', queryAction);

      // @TODO fix url for real api
      const url = new URL(FETCH_COLLECTIONS_URL, apiBaseUrl);
      // const url = new URL(FETCH_COLLECTIONS_URL + `/${payload?.action || ''}`, apiBaseUrl);
      url.search = new URLSearchParams(query).toString();

      const response = await axios.post(url.toString(), body, config)

      if (state.searchMode) {
        console.log('setting search results', response.data)
        await commit('SET_SEARCH_RESULTS', response.data);
      } else {
        switch (queryAction) {
          case 'followedbyme': {
            console.log('saving followed by me', response.data)
            await commit('SET_COLLECTION_FOLLOWED_BY_ME', response.data);
            break;
          }
          case 'createdbyme': {
            console.log('saving created by me')
            await commit('SET_COLLECTION_CREATED_BY_ME', response.data);
            break;
          }
          case 'sharedbyme': {
            await commit('SET_COLLECTION_SHARED_BY_ME', response.data);
            break;
          }
          case 'sharedwithme': {
            await commit('SET_COLLECTION_SHARED_WITH_ME', response.data);
            break;
          }
          default: {
            await commit('SET_SEARCH_RESULTS', response.data);
          }
        }
      }
    },
    async fetchCollection({commit, state, rootState, rootGetters, dispatch}, {id, hubCollection, source, application, rce, viewLog = false}) {
      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            'Content-Type': 'application/json',
            application: application || CONFIG.APPLICATION,
            source: source || 'searchmycollections',
            uid: rootState.uid,
            contentarea: rootGetters['users/contentArea'],
            sessionid: rootState.sessionId,
            rce
          })
        }
      };
      const searchDraftAndDocument = hubCollection ? '&searchDraftAndDocument=true' : '';
      const response = await axios.post(`${FETCH_COLLECTIONS_URL}?ItemID=${id}${searchDraftAndDocument}&noArchive=true`, {}, config)

      const collection = response.data?.resources?.[0];
      if (!collection) {
        return null;
      }
      await commit('SET_COLLECTION_DETAILS', collection);

      // logging for searching
      const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
      const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
      const _config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            userid: rootState.uid,
            school_codes: schoolCodes,
            email: rootState.email,
            sessionid: rootState.sessionId,
            roles: roles
          })
        }
      };
      axios.patch(`${searchBaseUrl}/urhsearch/${id}?action=opencollection`, {}, _config);

      // if navigated from hub or accessed from direct link
      if (viewLog) {
        const sanitizedBody = {
          resources: [{
            audit: collection.audit,
            record: collection.record,
            document: collection.document
          }]
        }
        dispatch('logForViewing', {itemId: collection.record.id, body: sanitizedBody}, {root: true})
      }

      //fetching sub resources
      await dispatch('fetchSubResources', { id, source, application });
      // await commit('SET_COLLECTION_ACCESS', response.data.userAccess);

      return collection;
    },
    async fetchSubResources({commit, state, rootState}, {id, source, application}) {
      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            'Content-Type': 'application/json',
            application: CONFIG.APPLICATION,
            source: 'dlrh',
            uid: rootState.uid,
            sessionid: rootState.sessionId
          })
        }
      };
      const response = await axios.post(`${FETCH_COLLECTIONS_URL}?collections.collectionId.id=${id}&noCollections=true&noDraft=true&noArchive=true&noMisc=true`, {}, config)

      const subResources = response.data?.resources;
      await commit('SET_SUB_RESOURCES', subResources);
      // await commit('SET_COLLECTION_ACCESS', response.data.userAccess);

      return subResources;
    },
    async followCollection({commit, state, rootState}, {collection, follow}) {
      const requestData = {
        uid: rootState.uid,
        id: collection.record.id,
        source: collection.record.collection,
        application: collection.audit.iss,
        follow
      }
      console.log(requestData)
      const response = await api.mycollecions.followCollection(collectionBaseUrl, requestData)
      if (response?.data) {
        commit('SET_COLLECTION_DETAILS', response.data)
      }
    },
    async addResources({commit, state, rootState}, payload) {

      const {collection, subResources} = payload

      if (state.loading) {
        await commit('SET_IS_LOADING', true, {root: true});
      }

      const config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            'Content-Type': 'application/json',
            application: collection.Application || CONFIG.APPLICATION,
            source: collection.Source || 'collections',
            uid: rootState.uid,
            sessionid: rootState.sessionId
          })
        }
      };

      const url = `${collectionBaseUrl}/collection/subresource`
      console.log('url ', url)

      const bodyFormData = new FormData();

      bodyFormData.append('resource', JSON.stringify({
        id: collection.ItemID,
        source: collection.Source || 'collections',
        application: collection.Application || CONFIG.APPLICATION
      }));

      const resources = subResources.map(resource => {
        return {
          id: resource.ItemID,
          source: resource.Source,
          application: resource.Application || CONFIG.APPLICATION
        }
      })

      bodyFormData.append('subResources', JSON.stringify(resources));

      const updatedCollection = await axios.post(url, bodyFormData, config).finally(() => {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }

        // logging for adding
        const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
        const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
        const _config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              userid: rootState.uid,
              school_codes: schoolCodes,
              email: rootState.email,
              sessionid: rootState.sessionId,
              roles: roles
            })
          }
        };
        axios.patch(`${searchBaseUrl}/urhsearch/${collection.ItemID}?action=addtocollection`, {}, _config);
      });
      return updatedCollection.data
    },
    async removeResources({commit, state, rootState}, payload) {

      const {collection, subResources} = payload

      if (state.loading) {
        await commit('SET_IS_LOADING', true, {root: true});
      }

      const bodyFormData = new FormData();

      bodyFormData.append('resource', JSON.stringify({
        id: collection.record.id,
        source: collection.record.collection || 'collections',
        application: collection.audit.iss || CONFIG.APPLICATION
      }));

      const resources = subResources.map(resource => {
        return {
          id: resource.resourceId.id,
          source: resource.resourceId.source,
          application: resource.resourceId.application
        }
      })
      bodyFormData.append('subResources', JSON.stringify(resources));

      const config = {
        method: 'delete',
        url: `${collectionBaseUrl}/collection/subresource`,
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            'Content-Type': 'application/json',
            application: collection.audit.iss || CONFIG.APPLICATION,
            source: collection.record.collection || 'collections',
            uid: rootState.uid,
            sessionid: rootState.sessionId
          })
        },
        data: bodyFormData
      };


      const updatedCollection = await axios(config).finally(() => {
        if (rootState.isLoading) {
          commit('SET_IS_LOADING', false, {root: true});
        }
      });
      console.log(updatedCollection)

      // logging for deletion
      const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
      const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
      const _config = {
        headers: {
          ...Vue.prototype.$OAuth.buildHttpHeader({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            userid: rootState.uid,
            school_codes: schoolCodes,
            email: rootState.email,
            sessionid: rootState.sessionId,
            roles: roles
          })
        }
      };
      console.log('collection = ', updatedCollection);
      axios.patch(`${searchBaseUrl}/urhsearch/${collection.record.id}?action=removefromcollection`, {}, _config);

      return updatedCollection.data
    },
    async saveMetaData({commit, state, rootState}, payload) {
      try {
        const {collection, document} = payload
        document.DateModified = new Date().toISOString().substring(0, 19);

        console.log(collection, document)
        const requestData = {
          uid: rootState.uid,
          firstName: rootState.users.userProfile.firstName,
          lastName: rootState.users.userProfile.lastName,
          resourceId: collection.record.id,
          source: collection.record.collection,
          newDocument: {...collection.document, ...document},
          published: collection.record.status === ResourceStatus.published,
          newFiles: collection.newFiles,
          newLinks: collection.newLinks
        }

        const response: any = await api.myresources.updateResource(apiBaseUrl, requestData);

        console.log('updating collection details with... ', response)
        // update store
        await commit('SET_COLLECTION_DETAILS', response.data);
        const alert = {
          type: 'success',
          text: 'Your collection has been saved.'
        }
        // show notification
        commit('SET_ALERT', alert, {root: true});
        console.log(`collection ${collection.ItemID} saved.`)
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error saving your resource.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },
    async publishOrUnPublishCollection({commit, state, rootState, rootGetters, dispatch}, payload) {
      commit('SET_IS_LOADING', true, {root: true});
      try {

        const {ItemID: resourceId, Source: repository} = payload.document;
        const {isPublished, application, originalId} = payload;

        const scope = RESOURCE_SCOPES.INTERNAL_SCOPE

        let resourcesURL;
        if (originalId) {
          resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?${scope}&action=${isPublished ? 'unpublish' : 'publish'}&originalId=${originalId}`;
        } else {
          resourcesURL = `${apiBaseUrl}/resourceeditor/${resourceId}?${scope}&action=${isPublished ? 'unpublish' : 'publish'}`;
        }

        const bodyFormData = new FormData();

        // collections do not have files yet
        const filesToKeep: any[] = [];

        bodyFormData.append('files', JSON.stringify([]));
        bodyFormData.append('filesToKeep', JSON.stringify(filesToKeep));

        bodyFormData.append('links', JSON.stringify([]));

        if (!isPublished) {
          const draft = {
            ...payload.document
          }
          bodyFormData.append('draft', JSON.stringify(draft));
        } else {
          const doc = {
            ...payload.document,
          }
          bodyFormData.append('document', JSON.stringify(doc));
        }
        bodyFormData.append('owner', payload.owner);

        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'multipart/form-data',
              application: application,
              source: repository, // should match to source/owner
              uid: rootState.uid,
              sessionid: rootState.sessionId
            })
          }
        };
        // note: if to unpublish a published resource, no payload is required
        const resource = await api.resources.publish(resourcesURL, bodyFormData, config)
        if (resource?.data) {
          // call EdPush Post
          const edPushResult = await dispatch('updateResourceState', {
            assignee: '',
            assigneeId: '',
            comments: '',
            action: isPublished ? EDPUSH_ACTION.UNPUBLISH : EDPUSH_ACTION.PUBLISH,
            application: application,
            ...(payload.owner && {
              source: repository,
              resourceId,
              name: payload.document.Name
            })
          });
          if (payload.displayAlert) {
            const alert = payload.isPublished ? {
              type: 'success',
              text: 'Your resource has been unpublished.'
            } : {
              type: 'success',
              text: 'Your resource has been published.'
            };
            commit('SET_ALERT', alert, {root: true});
          }
          if (rootState.isLoading) {
            commit('SET_IS_LOADING', false, {root: true});
          }
        }

        return resource.data;
      } catch (e) {
        console.error(e);
        if (payload.displayAlert) {
          const alert = {
            type: 'error',
            text: 'There was an error publishing your collection.'
          }
          commit('SET_ALERT', alert, {root: true});
        }
        return null;
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        if (payload.displayAlert) {
          commit('SET_DISPLAY_ALERT', true, {root: true});
          setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
        }
      }
    },
    async updateResourceState({state, rootState, rootGetters}, {
      type,
      application,
      assignee,
      assigneeId,
      comments,
      action,
      source,
      resourceId,
      name
    }) {
      try {
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              application: application,
              source,
              uid: rootState.uid,
              sessionid: rootState.sessionId,
              resourcetitle: name
            })
          }
        };
        const data = {type, assignee, assigneeId, comments};
        // update MongoDB
        const url = `${apiBaseUrl}/resourceeditor/state/${resourceId}?action=${action}`;
        const res = await api.resources.postResourceStatus(url, data, config);
        return res;
      } catch (e: any) {
        console.error(e);
      }
    },
    /**
     * @notification action
     * @paylod owner of the collection
     */
    async getCollectionNotification({commit, state, rootState}, payload) {

      // 1.) get collection id
      const { collectionId } = payload;

      // 2.) create header config
      const config = {
        headers: {
          // ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          uid: rootState.uid,
        }
      };

      // 3.) send request
      const url = `${apiBaseUrl}/audit/${collectionId}?viewmode=${payload.viewmode}`;
      const resNotification = await axios.get(url, config);
      commit('SET_COLLECTION_NOTIFICATION', resNotification.data);
      return resNotification?.data;
    },

    /**
     * @discussion
     * @paylod owner of the collection
     */
    async getCollectionDiscussion(context, payload) {
      const collectionId = payload;
      const discussion = await axios.get(`/api/discussion/${collectionId}`);
      context.commit('SET_DISCUSSION', discussion.data);
      return discussion?.data;
    },
    /**
     *
     * @param param0
     * @returns main collecton notification
     */
    async collectionNotification({commit, state, rootState}) {
      const uid = rootState.uid;
      const config = {
        headers: {
          // ...Vue.prototype.$OAuth.buildHttpHeader({
          'Content-Type': 'application/json',
          application: CONFIG.APPLICATION,
          source: 'collections',
          uid: rootState.uid,
        }
      };
      const resNotification = await axios.get(`https://resources.dev.education.nsw.gov.au/api/v1/notifications/${uid}`, config)
      commit('SET_NOTIFICATION', resNotification.data);
      return resNotification?.data;
    },
    /**
     * @shareCollections
     * Will mange both add and remove access to a collection
     * @displayAlert rootState
     * @getters displayAlert,
     * @mutation SET_DISPLAY_ALERT
     */
    async shareCollection({commit, state, rootState}, payload) {
      const shareCollections = null;
      let res: any = null

      // set loading to true.
      commit('SET_IS_LOADING', true, {root: true});

      try {
        // 1.) get resource Id to be shared.
        const collectionId = payload.collection ? payload.collection : state.collection.record.id;
        // 2.) get first name and last name of the authenticated users
        const {firstName, lastName, userId} = rootState.users.userProfile;
        // 3.a) url needs query params, header and body so that we can make a request to share resource.
        const query = `?action=${payload.action}`
        // 3.b) header
        const config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              'Content-Type': 'application/json',
              accept: 'application/json',
              application: CONFIG.APPLICATION,
              source: 'collections',
              sessionid: rootState.sessionId,
              uid: userId,
              first_name: firstName,
              last_name: lastName
            })
          }
        }
        // 4.) compose url to send a request to server.
        const url = `${collectionBaseUrl}/resources/share/${collectionId}${query}`

        // 5.) send request to api/resources
        res = await api.mycollecions.shareCollection(url, {userAccess: payload.userAccess}, config)
        commit('SET_COLLECTION_DETAILS', res.data)
        // 6.) commit SET_DISPLAY_ALERT to display alert
        if (res?.status === 200) {
          commit('SET_ALERT', payload.alert, {root: true});
        }

        // logging for sharing
        const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
        const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
        const _config = {
          headers: {
            ...Vue.prototype.$OAuth.buildHttpHeader({
              Accept: 'application/json',
              'Content-Type': 'application/json',
              userid: rootState.uid,
              school_codes: schoolCodes,
              email: rootState.email,
              sessionid: rootState.sessionId,
              roles: roles
            })
          }
        };
        await axios.patch(`${searchBaseUrl}/urhsearch/${collectionId}?action=sharecollection`, {}, _config);

        return res
      } catch (error) {
        console.log(error);
        const alert = {
          type: 'error',
          text: `Sharing ${payload.collectionName} was unsuccessful.`
        }
        commit('SET_ALERT', alert, {root: true});
        return new Error('Sorry unexpeted error occured.')
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }

    },
    /**
     * @copyCollection action copies the current collections.
     * @param param0
     * @param payload
     */
    async copyCollection({commit, state, rootState, dispatch, rootGetters, getters}, {action, collection}) {
      let copiedCollection: any = null;
      // 1.) set state to loading when copy collection is in progress
      commit('SET_IS_LOADING', true, {root: true});

      try {
        const originalCollection = {
          ...collection,
          // we still need to get document object from document when not published for my-collections as there is no draft object for my-collections
          document: collection.record.status === ResourceStatus.published ? collection.document : collection.draft.ItemID ? collection.draft: collection.document
        }
        // 2.) get details of collection that we are copying. for now we will set it type to any, but will be object.
        const requestCollection: any = {
          uid: rootState.uid,
          collection: originalCollection,
          action,
          application: collection.audit.iss,
          source: collection.record.collection,
          sessionid: rootState.sessionId
          // for now, we don't have archive available, this index will retrieve collection from archive.
          //index: state.resourceDetails.archive.findIndex(i => Number(i.record.version) === Number(version))
        }

        // 3.) set alret status
        const alert: { type: string, text: string } = {
          type: 'success',
          text: `Copied ${originalCollection.document.Name} successfully.`
        }

        if (getters.collectionCreatedByMe.meta.count >= rootGetters['users/upsConfig'].maxNumCollections) {
          commit('SET_ERROR_CREATE_DIALOG', true);
          return;
        } else {
          // 4.) make a copy before going to the new route.
          copiedCollection = await api.mycollecions.copyCollectionResource(collectionBaseUrl, requestCollection)

          await commit('SET_COLLECTION_DETAILS', copiedCollection.data);
          // set successful alert
          await commit('SET_ALERT', alert, {root: true});
          console.log('copiedCollection = ', copiedCollection);
          // logging for copying
          const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
          const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
          const _config = {
            headers: {
              ...Vue.prototype.$OAuth.buildHttpHeader({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                userid: rootState.uid,
                school_codes: schoolCodes,
                email: rootState.email,
                sessionid: rootState.sessionId,
                roles: roles
              })
            }
          };
          await axios.patch(`${searchBaseUrl}/urhsearch/${copiedCollection.data.record.id}?action=copycollection`, {}, _config);

          // 5.) then return copied collection
          return copiedCollection.data;
        }
      } catch (error) {
        console.log(error)
        // 5.) set alert if fails to copy
        const alert: { type: string, text: string } = {
          type: 'error',
          text: 'There was an error copying your collection'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        // 6.) trun off all setting
        commit('SET_IS_LOADING', false, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },

    async deleteCollection({commit, state, rootState, dispatch}, payload) {
      try {
        commit('SET_IS_LOADING', true, {root: true});

        const subResources = payload.subResources?.map(resource => resource.resourceId)
        const requestData = {
          uid: rootState.uid,
          firstName: rootState.users.userProfile.firstName,
          lastName: rootState.users.userProfile.lastName,
          resourceId: payload.collectionId,
          source: payload.source,
          application: payload.application,
          subResources: subResources
        }

        const response = await api.myresources.deleteResource(apiBaseUrl, requestData);

        // BE requires additional time to update downstream systems
        if (response.status === 200) {
          await delay(1000);
          commit('SET_IS_LOADING', false, {root: true});
        }
        const alert = {
          type: 'success',
          text: 'Your collection has been deleted.'
        }
        commit('SET_ALERT', alert, {root: true});
        return response
      } catch (e) {
        console.error(e);
        const alert = {
          type: 'error',
          text: 'There was an error deleting your collection.'
        }
        commit('SET_ALERT', alert, {root: true});
      } finally {
        commit('SET_IS_LOADING', false, {root: true});
        commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
    },

    /**
     *
     * @param context
     * @param payload will be sent from Learning Sequence which will then update the state of sequence we store here
     */
    async sequenceOrder({commit, state, rootState}, {document, subResources, audit, record}) {
      try {
        commit('SET_IS_LOADING', true, {root: true});

        // 1.) in order to save a sequence we need to send the original resourceId which is document.ItemID
        // need to send  as a form data.
        const bodyFormData = new FormData();

        // 2.) Sub resource that was sequenced, and only send its resourceId object
        const sub = subResources.map(item => item.resourceId)

        // 3.) append both subResources and resource id, both subResources and resource needs source and applications,
        // without that, put request will throw error of 500.

        bodyFormData.append('subResources', JSON.stringify(sub))
        bodyFormData.append('resource', JSON.stringify({
          id: record.id,
          source: record.collection || 'collections',
          application: audit?.iss || CONFIG.APPLICATION
        }));

        // 4.) make a put request to the server with original resource and subResource that was sequenced.
        const response = await api.mycollecions.sequenceOrderCollection(collectionBaseUrl, bodyFormData, {uid: rootState.uid})
        const collection = response?.data
        commit('SET_IS_LOADING', false, {root: true});

        if (collection) {
          commit('SET_COLLECTION_DETAILS', collection)
          console.log('collection = ', collection)
          // logging for ordering
          const schoolCodes = Array.isArray(rootState.schoolCodes) && rootState.schoolCodes.join(', ') || rootState.schoolCodes;
          const roles = Array.isArray(rootState.roles) && rootState.roles.join(', ') || rootState.roles;
          const _config = {
            headers: {
              ...Vue.prototype.$OAuth.buildHttpHeader({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                userid: rootState.uid,
                school_codes: schoolCodes,
                email: rootState.email,
                sessionid: rootState.sessionId,
                roles: roles
              })
            }
          };
          await axios.patch(`${searchBaseUrl}/urhsearch/${collection.data.data.resource.record.id}?action=createlearningsequence`, {}, _config);
        }
        return collection
      } catch (error) {
        console.log(error)
      }
    }
  },
}


export default moduleMyCollections
