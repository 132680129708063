<template>
  <v-app id="v-app">
    <SkipLink
      id="main-content"
      v-infinite-scroll="updateHitBottom"
      infinite-scroll-distance="10"
    >
      <NavigationDrawer
        v-if="$store.state.allowAccess"
        :items="navigationItems"
        class="navigation-drawer"
      />

      <Notifications :notificationData="$store.state.myResources.notifications" />

      <AppBar
        v-if="$store.state.allowAccess && !hideAppBar"
        color="primary"
        app-name="my-nsw-gov"
        title="Universal Resources Hub"
        :search-filter-mutator="true"
        :show-full-screen="false"
        :show-nav-icon="true"
        :show-notifications="true"
      >
        <template #appIcon>
          <v-icon
            alt=""
            x-large
          >
            $vuetify.icons.google
          </v-icon>
        </template>
        <template
          v-if="hasProfile"
          #profile
        >
          <Profile
            :givenName="profile.givenName"
            :surname="profile.sn"
            :jobTitle="jobTitle"
            :updateProfile="false"
            :changePassword="false"
            :updateSecretQuestion="false"
            :portalSettings="false"
            :logout="true"
            :logoutHandler="logout"
          />
        </template>
      </AppBar>

      <OnboardingDialog v-model="showOnboardingDialog" />

      <v-main
        id="content"
        style="min-height: 100%;"
      >
        <v-container
          fluid
          class="app-main__container"
        >
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </v-container>
      </v-main>

      <Alert
        v-if="displayAlert && $store.getters.alert.text"
        :showAlert="displayAlert"
        :type="$store.getters.alert.type"
        :htmlText="$store.getters.alert.text"
        :position="$vuetify.breakpoint.xsOnly ? 'top-center' : 'left-bottom'"
        role="status"
        allowDismiss
        multiLine
        @dismiss="displayAlert = false"
      />
    </SkipLink>
  </v-app>
</template>

<script>
import {Alert, NavigationDrawer, SkipLink} from '@nswdoe/doe-ui-core';
import AppBar from './components/AppBar';
import Profile from './components/Profile';
import Notifications from '@/components/Notifications';
import OnboardingDialog from '@/components/Dialogs/OnboardingDialog';
import {COMPONENT_CONFIG, NAVIGATION_DRAWER_ITEMS} from '@/constants';
import {mapGetters} from 'vuex';

function hashCode(str) {
  return str.split('').reduce((prevHash, currVal) =>
    (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}

const Config = window.dlrhConfig;

export default {
  name: 'App',
  components: {
    OnboardingDialog,
    AppBar,
    NavigationDrawer,
    Profile,
    Alert,
    SkipLink,
    Notifications
  },
  data() {
    return {
      showOnboardingDialog: false,

      alertTimeout: COMPONENT_CONFIG.alert.timeout,
      // items for NavigationDrawer component
      items: NAVIGATION_DRAWER_ITEMS
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/userProfile',
      isSuperAdmin: 'users/isSuperAdmin',
    }),
    displayAlert: {
      get() {
        return this.$store.state.displayAlert;
      },
      set(val) {
        this.$store.commit('SET_DISPLAY_ALERT', val);
      }
    },
    oauthLoaded() {
      return this.$OAuth.loaded;
    },
    hasProfile() {
      return this.$OAuth.data.profile != null;
    },
    profile() {
      return this.$OAuth.data.profile || {givenName: 'Anonymous', sn: 'User'};
    },
    jobTitle() {
      const roles = this.profile.roles;
      if (roles && roles.length) {
        if (roles.indexOf('SCHOOL.PRINCIPAL') != -1) {
          return 'Principal';
        }

        if (roles.indexOf('SCHOOL.TEACHER') != -1) {
          return 'Teacher';
        }

        if (roles.indexOf('SCHOOL.OTHERCORPORATE') != -1) {
          return 'Other Corporate';
        }

        if (roles.indexOf('DET.CONTRACTOR') != -1) {
          return 'Contractor';
        }
      }
      return 'Staff';
    },
    hideAppBar() {
      return this.$vuetify.breakpoint.xsOnly && this.$route.name === 'MyResourceDetailsPage';
    },
    navigationItems() {
      const hasAppRole = this.userProfile?.appRole?.length > 0;
      if (this.isSuperAdmin) {
        return this.items;
      } else if (hasAppRole) {
        return this.items.filter(item => item.accessLevel < 4);
      } else {
        return this.items.filter(item => item.accessLevel < 3);
      }
    }
  },
  watch: {
    oauthLoaded: {
      immediate: true,
      async handler(loaded) {
        if (loaded) {
          // oAuth returned
          if (!this.$OAuth.data.profile.roles) {
            this.$OAuth.data.profile.roles = this.$OAuth.data.profile['det-roles'];
          }
          if (!this.$OAuth.data.profile.uid) {
            this.$OAuth.data.profile.uid = this.$OAuth.data.profile['det-username'] || this.$OAuth.data.profile['UserID'];
          }
          if (Config.ACL_TYPE && Config.ACL_TYPE === 'role') {
            if (this.$OAuth.data.profile.roles) {
              if (typeof this.$OAuth.data.profile.roles === 'string') {
                // DET.CONTRACTOR
                if (Config.ROLE_ACCESS.includes(this.$OAuth.data.profile.roles)) {
                  this.$store.commit('SET_ALLOW_ACCESS', true);
                  localStorage.setItem('allow_access', 'true');
                }
              } else if (Array.isArray(this.$OAuth.data.profile.roles)) {
                for (let role of this.$OAuth.data.profile.roles) {
                  if (Config.ROLE_ACCESS.includes(role)) {
                    this.$store.commit('SET_ALLOW_ACCESS', true);
                    localStorage.setItem('allow_access', 'true');
                    break;
                  }
                }
              }
            }
          } else if (Config.ACL_TYPE && Config.ACL_TYPE === 'adgroups') {
            if (this.$OAuth.data.profile?.acl) {
              if (typeof this.$OAuth.data.profile.acl === 'string') {
                if (Config.ADGROUPS.includes(this.$OAuth.data.profile.acl)) {
                  this.$store.commit('SET_ALLOW_ACCESS', true);
                  localStorage.setItem('allow_access', 'true');
                }
              } else if (Array.isArray(this.$OAuth.data.profile.acl)) {
                for (let acl of this.$OAuth.data.profile.acl) {
                  if (Config.ADGROUPS.includes(acl)) {
                    this.$store.commit('SET_ALLOW_ACCESS', true);
                    localStorage.setItem('allow_access', 'true');
                    break;
                  }
                }
              }
            }
          }

          if (!this.$store.state.allowAccess) {
            localStorage.setItem('allow_access', 'false');
            await this.$router.push({name: 'AccessDeniedError'});
          } else {
            console.log(this.$OAuth)
            // have access, 1. save user's Oauth data to store
            this.$store.commit('SET_UID', this.$OAuth.profile.UserID.toLowerCase());
            console.log(this.$store.state.uid)
            this.$store.commit('SET_SCHOOL_CODES', this.$OAuth.profile.school_codes);
            this.$store.commit('SET_EMAIL', this.$OAuth.profile.email);
            this.$store.commit('SET_ROLES', this.$OAuth.profile.roles);
            // this.$store.commit('SET_SESSION_ID', this.$OAuth.profile['at_hash']);
            this.$store.commit('SET_SESSION_ID', this.$OAuth.profile.aio);

            // google analytics

            // for qualtrics
            const schoolCode1 = Array.isArray(this.$OAuth.data.profile.school_codes) ? this.$OAuth.data.profile.school_codes?.[0] : this.$OAuth.data.profile.school_codes
            const roles = Array.isArray(this.$OAuth.data.profile.roles) ? this.$OAuth.data.profile.roles.join() : this.$OAuth.data.profile.roles

            const uidHash = hashCode(this.$OAuth.profile.uid.toLowerCase());
            window.dataLayer.push({
              'event': 'login',
              'user_id': uidHash,
              'school_code': this.$OAuth.profile.school_codes,
              'school_code_1': schoolCode1,
              'role': roles
            });

            const userRequest = {
              userId: this.$store.state.uid,
              firstName: this.$OAuth.profile.givenName,
              lastName: this.$OAuth.profile.sn,
              email: this.$OAuth.profile.email,
              onBoarded: false,
              schoolCode: this.$OAuth.profile.school_codes,
              role: this.$OAuth.profile.roles
            };
            // 2. call APIs to fetch user profile and notifications
            //await this.$store.dispatch('metadata/fetchFiltersMetadata'); // this is a doubling of the filter API call, it's already being performed on the router index.ts for /home
            await this.$store.dispatch('metadata/fetchStatusesMetadata');
            //await this.$store.dispatch('users/getUpsConfig'); - not required as config is stored in the MDS
            await this.$store.dispatch('users/fetchOrCreateUserProfile', userRequest);
            await this.$store.dispatch('myResources/getNotifications');
            await this.$store.dispatch('editor/loadStatusCounts');

            // check if need to display OnboardingDialog
            //console.log('\x1b[36m%s\x1b[0m', 'Show Dialog');  //cyan
            this.showOnboardingDialog = (!this.$store.state.users.userProfile.onBoarded) && loaded && (this.$route.path !== '/notfound');

            // autosave is fired when expiration time is due in 2 minutes
            const intervalId = setInterval(() => {
              const expirationTime = localStorage.getItem('token_expiry');
              const remainingTime = (expirationTime * 1000) - Date.now();
              if (remainingTime < this.$store.getters['metadata/autosaveTimer']) {
                this.$store.commit('myResources/SET_TOKEN_WILL_EXPIRE', true);
                clearInterval(intervalId);
              }
            }, 60000);
          }
        }
      }
    }
  },
  mounted() {
    // call APIs to initialize app data
    /*    this.$store.dispatch('metadata/fetchFiltersMetadata');
        this.$store.dispatch('users/getUpsConfig');*/
  },
  methods: {
    logout() {
      this.$OAuth.logout();
    },
    updateHitBottom() {
      this.$store.commit('SET_HIT_BOTTOM_COUNT');
    }
  }
};
</script>

<style lang="scss">
@import "src/scss/ads";
@import "src/scss/variables";

.theme--light {
  // override the pre-defined scss classes for <a> in ads.scss as per the design for this project
  a:not(.v-btn, .v-tab) {
    color: $ads-blue-1;

    &:visited {
      color: $ads-blue-1;
    }
  }

  &.v-application {
    background-color: $ads-light-10 !important;
    overflow: hidden;

    .subtitle {
      background: initial !important;
    }
  }
}

.app-main__container {
  padding: 0;
  margin: 0;
  min-height: 100%;
}

.v-navigation-drawer {
  .v-list {
    .v-list-item {
      min-height: 64px;
      box-sizing: border-box;

      &:not(.expandable) {
        border: 2px solid transparent !important;
      }

      &.expandable {
        margin-bottom: 4px;
      }

      .row {
        min-height: 64px;
      }

      &:focus {
        border: 2px solid $ads-navy !important;
        outline: none !important;
      }
    }

    .expandable {
      .nav-bar--menu-group {
        .v-item-group {
          .v-expansion-panel {
            .v-expansion-panel-header {
              border: 2px solid transparent;

              &.px-3 {
                padding-right: 8px !important;
              }

              &:focus {
                outline: none !important;
                border-color: $ads-navy;
              }
            }
          }
        }
      }
    }
  }
}

div.v-navigation-drawer__content > div > div > div > a:last-child > div {
  border-top: 1px solid #CDD3D6;
}

.app-resource-title-mobile {
  font-size: 16px;
}

.font-size-14px-mobile {
  font-size: 14px;
}

.sort-by-mobile {
  width: inherit;
}

.fontColorResourceType13 {
  color: $ads-blue-1;
}

.v-btn:focus {
  outline: $ads-blue-2 auto 10px !important;
}
</style>
